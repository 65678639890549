<template>
  <v-card>
    <v-row>
      <v-col class="col-6 mx-auto">
        <v-select
          v-model="messageFilter"
          :items="filterOptions"
          label="Message Filter"
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="filteredData">
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.teacher_name }}</td>
          <td>{{ item.student_name }}</td>
          <td v-if="item.messages_list.length > 0">
            <v-btn @click="openChatDialog(item.messages_list)">Chat Detail</v-btn>
          </td>
          <td v-else>
            No Messages
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>Chat Results</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(message, index) in currentMessages" :key="index">
              <v-list-item-content>
                <v-row>
                  <v-col class="left-column">{{ message.sender }}</v-col>
                  <v-col class="middle-column">{{ message.message }}
                    <br />
                    <small class="ma-2">
                      {{ moment(message.created_at).format('Do MMM YYYY, h:mm:ss a')}}
                    </small>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeChatDialog" color="primary">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      apiData: [], // Your API data
      headers: [
        { text: "Teacher Name", value: "teacher_name" },
        { text: "Student Name", value: "student_name" },
        { text: "Chat", value: "chat", sortable: false },
      ],
      dialog: false,
      currentMessages: [],
      messageFilter: "All", // Default to show all items
      filterOptions: ["All", "With Message", "Without Message"],
    };
  },
  computed: {
    filteredData() {
      // Filter the data based on the selected filter
      if (this.messageFilter === "With Message") {
        return this.apiData.filter((item) => item.messages_list.length > 0);
      } else if (this.messageFilter === "Without Message") {
        return this.apiData.filter((item) => item.messages_list.length === 0);
      } else {
        // "All" - show all items
        return this.apiData;
      }
    },
  },
  created() {
    // Make an Axios request to fetch data
    this.$api
      .get("/listing/chat_message/")
      .then((response) => {
        this.apiData = response.data.results;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
  methods: {
    openChatDialog(messages) {
      this.currentMessages = messages;
      this.dialog = true;
    },
    closeChatDialog() {
      this.dialog = false;
    },
  },
};
</script>
